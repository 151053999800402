<template>
  <div>
    <AppMenu />
    <div class="bg-white">
      <div class="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
        <div class="pb-16 xl:flex xl:items-center xl:justify-between">
          <div>
            <h1 class="text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
              <span class="text-gray-900">Sostieni il centro cerca e ri</span>
              <span class="text-blue-700">Cerca</span>
            </h1>
            <p class="mt-5 text-xl text-gray-500">
              Il Centro Cerca e riCerca ha da sempre a cuore la diagnosi, la
              terapia e la ricerca sulle patologie delle alte vie digestive.
            </p>
          </div>
          <a
            target="_blank"
            href="https://register.hsr.it/donazioni/donazione.aspx"
            class="inline-flex items-center justify-center w-full px-5 py-3 mt-8 text-lg font-extrabold text-white uppercase bg-blue-700 border border-transparent rounded-md hover:bg-blue-700 sm:mt-10 sm:w-auto xl:mt-0"
            >DONAZIONE</a
          >
        </div>
        <div
          class="pt-16 border-t border-gray-200 xl:grid xl:grid-cols-3 xl:gap-x-8"
        >
          <div>
            <h2
              class="text-base font-semibold tracking-wide text-blue-700 uppercase"
            >
              Sostienici
            </h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900">
              Aiuta il centro a crescere
            </p>
            <p class="mt-4 text-lg text-gray-500">
              Effettuando una donazione puoi supportare ricercatori e clinici
              del centro Cerca e riCerca impegnati quotidianamente per
              migliorare l’approccio di cura in un’ottica di personalizzazione
              ed efficacia.
            </p>
          </div>
          <div
            class="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2"
          >
            <ul class="divide-y divide-gray-200">
              <li class="flex py-4 md:py-0 md:pb-4">
                <!-- Heroicon name: outline/check -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span class="ml-3 text-base text-gray-500">
                  {{ $t("donation1") }}
                </span>
              </li>

              <li class="flex py-4">
                <!-- Heroicon name: outline/check -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span class="ml-3 text-base text-gray-500">
                  {{ $t("donation2") }}</span
                >
              </li>

              <li class="flex py-4">
                <!-- Heroicon name: outline/check -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span class="ml-3 text-base text-gray-500">
                  {{ $t("donation3") }}</span
                >
              </li>
            </ul>
            <ul
              class="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0"
            >
              <li class="flex py-4 md:border-t-0 md:py-0 md:pb-4">
                <!-- Heroicon name: outline/check -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span class="ml-3 text-base text-gray-500">
                  {{ $t("donation4") }}</span
                >
              </li>

              <li class="flex py-4">
                <!-- Heroicon name: outline/check -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span class="ml-3 text-base text-gray-500">
                  {{ $t("donation5") }}</span
                >
              </li>

              <li class="flex py-4">
                <!-- Heroicon name: outline/check -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span class="ml-3 text-base text-gray-500">
                  {{ $t("donation6") }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <CtaSection />
    <FooterSection />
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu";
import FooterSection from "../components/FooterSection.vue";
import CtaSection from "../components/CtaSection.vue";

export default {
  name: "Donations",
  components: {
    AppMenu,
    FooterSection,
    CtaSection,
  },
};
</script>
